// Social
import Behance from './social/Behance.Icon';
import Dribbble from './social/Dribbble.Icon';
import Facebook from './social/Facebook.Icon';
import Instagram from './social/Instagram.Icon';
import Twitter from './social/Twitter.Icon';
import LinkedIn from './social/LinkedIn.Icon';
import Medium from './social/Medium.Icon';
import Github from './social/Github.Icon';
import Stackoverflow from './social/Stackoverflow.Icon';
import Unsplash from './social/Unsplash.Icon';
import YouTube from './social/YouTube.Icon';
import Patreon from './social/Patreon.Icon';
import Paypal from './social/Paypal.Icon';
import DigitalOcean from './social/DigitalOcean.Icon';
import Spaceout from './social/Spaceout.Icon';

// UI
import ChevronLeft from './ui/ChevronLeft.Icon';
import Copied from './ui/Copied.Icon';
import Copy from './ui/Copy.Icon';
import Ex from './ui/Ex.Icon';
import Link from './ui/Link.Icon';
import ToggleOpen from './ui/ToggleOpen.Icon';
import ToggleClose from './ui/ToggleClose.Icon';
import Rows from './ui/Rows.Icon';
import Tiles from './ui/Tiles.Icon';
import FullScreenEnter from './ui/FullScreenEnter.Icon';
import FullScreenExit from './ui/FullScreenExit.Icon';
import FontSetter from './ui/FontSetter.Icon';
import ArrowUp from './ui/ArrowUp.Icon';
import Search from './ui/Search.Icon';

//DEV Icons

import { ReactComponent as Apollo } from './devIcons/apollo.svg';
import { ReactComponent as Aws } from './devIcons/aws.svg';
import { ReactComponent as Azure } from './devIcons/azure.svg';
import { ReactComponent as Css } from './devIcons/css.svg';
import { ReactComponent as Cypress } from './devIcons/cypress.svg';
import { ReactComponent as Docker } from './devIcons/docker.svg';
import { ReactComponent as Express } from './devIcons/express.svg';
import { ReactComponent as Gatsby } from './devIcons/Gatsby-Monogram.svg';
import { ReactComponent as Ghostjs } from './devIcons/ghostjs.svg';
import { ReactComponent as HandleBars } from './devIcons/handlebars.svg';
import { ReactComponent as Heroku } from './devIcons/heroku.svg';
import { ReactComponent as Html } from './devIcons/html.svg';
import { ReactComponent as JS } from './devIcons/js.svg';
import { ReactComponent as Mongo } from './devIcons/mongo.svg';
import { ReactComponent as MySQL } from './devIcons/mysql.svg';
import { ReactComponent as Netlify } from './devIcons/netlify.svg';
import { ReactComponent as Nextjs } from './devIcons/nextjs.svg';
import { ReactComponent as Nodejs } from './devIcons/nodejs.svg';
import { ReactComponent as Owasp } from './devIcons/owasp.svg';
import { ReactComponent as Postgre } from './devIcons/postgre.svg';
import { ReactComponent as ReactIcon } from './devIcons/react.svg';
import { ReactComponent as Svelte } from './devIcons/svelte.svg';
import { ReactComponent as Tailwind } from './devIcons/tailwind.svg';
import { ReactComponent as TS } from './devIcons/ts.svg';
import { ReactComponent as Wordpress } from './devIcons/wordpress.svg';
import { ReactComponent as Yml } from './devIcons/yml.svg';
import { ReactComponent as Rust } from './devIcons/rust.svg';
import { ReactComponent as C } from './devIcons/c.svg';
import { ReactComponent as Astro } from './devIcons/astro.svg';
import { ReactComponent as Actix } from './devIcons/actix.svg';

export default {
  C,
  Actix,
  Astro,
  Behance,
  Dribbble,
  Facebook,
  Instagram,
  Twitter,
  LinkedIn,
  Medium,
  Github,
  Stackoverflow,
  Unsplash,
  YouTube,
  Patreon,
  Paypal,
  DigitalOcean,
  Spaceout,
  ChevronLeft,
  Copied,
  Copy,
  Ex,
  Link,
  ToggleClose,
  ToggleOpen,
  Rows,
  Tiles,
  FullScreenExit,
  FullScreenEnter,
  FontSetter,
  ArrowUp,
  Search,
  ReactIcon,
  Wordpress,
  JS,
  Css,
  Express,
  HandleBars,
  Html,
  MySQL,
  Netlify,
  Postgre,
  Mongo,
  TS,
  Gatsby,
  Svelte,
  Ghostjs,
  Nextjs,
  Tailwind,
  Apollo,
  Aws,
  Azure,
  Docker,
  Heroku,
  Yml,
  Cypress,
  Owasp,
  Nodejs,
  Rust,
};
